
import { defineComponent } from 'vue';
import PersonaBaseLayout from '@/components/layout/base/BaseLayout.vue';
import { useRoute } from "vue-router";
import PersonaForm from '@/components/form/Form.vue';

export default defineComponent({
    name: 'FormProjectRequestPage',
    components: {
        PersonaBaseLayout,
        PersonaForm
    },
    data() {
        return {
            currentPath: this.$route.path,
        };
    }
});
